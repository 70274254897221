/** @format */

import React from 'react';
import econommi from '../assets/econommi.png';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import linkedin from '../assets/linkedin.png';
import twitter from '../assets/twitter.png';

function Footer() {
	return (
		<div className='footer'>
			<div className='title'>
				<a href='https://econommi.io/'>
					<img src={econommi} alt=''></img>
					<div className='copyright'>Copyright © 2022 econommi. All Rights Reserved.</div>
				</a>
			</div>
			<div className='socials'>
				<a href='https://www.instagram.com/econommi/'>
					<img src={instagram} style={{ width: '40px' }} alt=''></img>
				</a>
				<a href='https://www.facebook.com/econommi/'>
					<img src={facebook} style={{ width: '40px' }} alt=''></img>
				</a>
				<a href='https://ca.linkedin.com/company/econommi'>
					<img src={linkedin} style={{ width: '40px' }} alt=''></img>
				</a>
				<a href='https://twitter.com/econommi'>
					<img src={twitter} style={{ width: '40px' }} alt=''></img>
				</a>
			</div>
		</div>
	);
}

export default Footer;
