/** @format */

import React, { useState } from 'react';
import dropdownIcon from '../assets/dropDown.png';

function Dropdown({ hearUs, setHearUs }) {
	const [dropList, setDropList] = useState(false);
	const [fillPerson, setFillPerson] = useState(false);
	const [fill, setFill] = useState('');

	const handleClick = (event) => {
		setDropList((prev) => !prev);
	};

	return (
		<div className='dropDown'>
			<button onClick={handleClick}>
				
					<div className='name'>{hearUs ? fillPerson ? 'How did you hear about us?' : hearUs : 'How did you hear about us?'}</div>
				
				<img
					className={dropList ? 'dropped' : ' '}
					src={dropdownIcon}
					alt=''></img>
			</button>

			{dropList && (
				<div style={{position: 'absolute', width: window.screen.width > 1025 ? '327.4px' : window.screen.width > 244 ? 'calc(100% - (40px))' : '204px'}} className='options' dropList={dropList}>
					<div
						className='option'
						onClick={() => {
							setHearUs('Social Media');
							setFillPerson(false);
							setDropList(false);
						}}>
						Social Media
					</div>
					<div
						className='option'
						onClick={() => {
							setHearUs('QR Code');
							setFillPerson(false);
							setDropList(false);
						}}>
						QR Code
					</div>
					<div
						className='option'
						onClick={() => {
							setFillPerson(true);
							setDropList(false);
							setHearUs('');
							setFill('Enter the person name');
						}}>
						{' '}
						Person (Full Name)
					</div>
					<div
						className='option'
						style={{ borderBottom: 'none' }}
						onClick={() => {
							setFillPerson(true);
							setDropList(false);
							setHearUs('');
							setFill('What other option?');
						}}>
						Other (Please specify)
					</div>
				</div>
			)}
			{fillPerson && 
			<div style={{marginTop:'15px'}} className="dropDown input">
			<input
			className="dropDown input"
						placeholder={fill}
						value={hearUs}
						onChange={(e) => setHearUs(e.target.value)}/>
						</div>
						}
		</div>
	);
}

export default Dropdown;
