/** @format */

import React, { useState } from 'react';
import ipad from '../assets/ipad.png';
import airpod from '../assets/airpod.png';
import googleMini from '../assets/googleMini.png';
import Dropdown from './Dropdown';

function Waitlist({ setSubmitted, submitted }) {
	const [firstName, setFirstNamme] = useState('');
	const [lastName, setLastNamme] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [email, setEmail] = useState('');
	const [hearUs, setHearUs] = useState('');
	const [inputErrors, setInputErrors] = useState([]);

	function onSubmit() {
		if (
			firstName == '' ||
			lastName == '' ||
			postalCode == '' ||
			email == '' ||
			!email.includes('@') ||
			!testCanadianPostalCode(postalCode)
		) {
			let tempErrors = [];
			firstName == '' && tempErrors.push('firstName');
			lastName == '' && tempErrors.push('lastName');
			postalCode == '' && tempErrors.push('postalCode');
			email == '' && tempErrors.push('email');

			if (!email.includes('@')) {
				window.confirm('Please put a valid email');
				tempErrors.push('email');
			} else if (!testCanadianPostalCode(postalCode)) {
				window.confirm('Invalid postal code');
				tempErrors.push('postalCode');
			} else {
				window.confirm('Please make sure everything is valid');
			}

			setInputErrors(tempErrors);
		} else {
			setInputErrors([]);
			setSubmitted(true);

			fetch('https://hooks.zapier.com/hooks/catch/7559792/bicvnw4/', {
				method: 'POST',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
				},
				body: JSON.stringify({
					first_name: firstName,
					last_name: lastName,
					postal_code: postalCode,
					email: email,
					hear_us: hearUs,
				}),
			})
				.then((res) => res.json)
				.then((response) => console.log(response));
		}
	}

	function testCanadianPostalCode(postalCode) {
		const postalCodeRegex = new RegExp(
			/^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/i
		);
		console.log(postalCodeRegex.test(postalCode));

		return postalCodeRegex.test(postalCode);
	}

	return (
		<div className='waitlist'>
			{!submitted ? (
				<>
					<div className='waitlist_description'>
						Join <b style={{ color: '#D4AF37', fontWeight: '300' }}>the</b>{' '}
						<b style={{ color: '#D4AF37', fontWeight: '700' }}>Waitlist</b> to be part of the
						revolution that shifts the world's{' '}
						<b style={{ fontStyle: 'italic', fontWeight: '400' }}>debt-based</b> economy to{' '}
						<b style={{ fontStyle: 'italic', fontWeight: '400' }}>asset-backed</b> one{' '}
					</div>
					<div className='waitlist_details'>
						<div className='waitlist_input'>
							<div className='inputs'>
								<div className={inputErrors.includes('firstName') ? 'input error' : 'input'}>
									<p>First Name</p>
									<input
										placeholder='Enter your first name'
										value={firstName}
										onChange={(e) => setFirstNamme(e.target.value)}></input>
								</div>
								<div className={inputErrors.includes('lastName') ? 'input error' : 'input'}>
									<p>Last Name</p>
									<input
										placeholder='Enter your last name'
										value={lastName}
										onChange={(e) => setLastNamme(e.target.value)}></input>
								</div>
								<div className={inputErrors.includes('postalCode') ? 'input error' : 'input'}>
									<p>Postal Code</p>
									<input
										placeholder='Enter your postal code'
										value={postalCode}
										onChange={(e) => setPostalCode(e.target.value)}></input>
								</div>
								<div className={inputErrors.includes('email') ? 'input error' : 'input'}>
									<p>Email</p>
									<input
										placeholder='Enter your email address'
										value={email}
										type='email'
										maxLength='64'
										required
										pattern='.+@beststartupever\.com'
										onChange={(e) => setEmail(e.target.value)}></input>
								</div>
								<Dropdown hearUs={hearUs} setHearUs={setHearUs} />
							</div>
							<div className='button'>
								<button onClick={onSubmit}>Join Now</button>
							</div>
						</div>
						<div className='waitlist_prizes'>
							<div className='headTitle'>
								Want a Head Start? <b style={{ fontWeight: '700' }}>SIGN UP NOW!</b>
							</div>
							<div className='details'>
								<div className='membership'>
									<div className='membership_text'>
										You will be rewarded a{' '}
										<b style={{ fontWeight: '400', color: '#CD7F32' }}>BRONZE</b> membership
									</div>
									<div className='membership_logo'>
										<div className='membership_circle'>
											<b>Bronze</b>
											Membership
										</div>
										<div className='logo_text'>
											Free Bronze <br /> Membership
										</div>
									</div>
								</div>
								<div className='prizes'>
									<div className='prizes_text'>
										You could also have a chance to <b style={{ fontWeight: '700' }}>WIN</b> one of
										these prizes through a random draw!
									</div>
									<div className='prizes_box'>
										<div className='products'>
											<div className='image'>
												<img className='ipad' src={ipad} alt=''></img>
											</div>
											Apple <b>iPad Air</b>
										</div>
										<div className='products'>
											<div className='image'>
												<img className='airpod' src={airpod} alt=''></img>
											</div>
											Apple <b>AirPods</b>
										</div>
										<div className='products'>
											<div className='image lt'>LT</div>
											FREE <b>LeaseTokens</b>
										</div>
										<div className='products'>
											<div className='image'>
												<img className='googleMini' src={googleMini} alt=''></img>
											</div>
											Google <b>Home Mini</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<div className='thankyou'>
					<div className='title'>
						Thank you for signing up for <span>the</span> <b>Waitlist!</b>
					</div>
					<div className='desc'>Updates will be sent through your email.</div>
					<div className='button'>
						<button onClick={() => setSubmitted(false)}>Return to Homepage</button>
					</div>
				</div>
			)}
		</div>
	);
}

export default Waitlist;
