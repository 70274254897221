/** @format */
import React, { useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Toppage from './sections/Toppage';
import Waitlist from './sections/Waitlist';
import Econommi from './sections/Econommi';
import Membership from './sections/Membership';
import Lending from './sections/Lending';
import Footer from './sections/Footer';

function App() {
	const [submitted, setSubmitted] = useState(false);

	return (
		<div className='App'>
			<Toppage />
			<Waitlist setSubmitted={setSubmitted} submitted={submitted} />
			{!submitted && (
				<>
					<Econommi />
					<Membership />
					<Lending />
				</>
			)}
			<Footer />
		</div>
	);
}

export default App;
