/** @format */

import React from 'react';
import vip from '../assets/vip.png';
import booster from '../assets/booster.png';
import encore from '../assets/encore.png';
import money from '../assets/money.png';
import encoreplus from '../assets/encoreplus.png';
import clock from '../assets/clock.png';

function Membership() {
	return (
		<div className='membership_section'>
			<div className='membership'>
				<div className='membership_title'>Membership</div>
				<div className='membership_desc'>
					Membership is unlocked when you meet the Token Ratio (a certain % of your{' '}
					<b>LeaseToken</b> balance in <b>ECON</b>)
				</div>
				<div className='membership_table'>
					<div className='table_top'>
						<div className='text'>Membership Perks</div>
						<div className='colors'>
							<div>BRONZE</div>
							<div>SILVER</div>
							<div>GOLD</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={vip} alt=''></img> Early access to{'  '}
							<span> new features, special events and more</span>
						</div>
						<div className='colors'>
							<div className='bronze'>
								<div></div>
							</div>
							<div className='silver'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={booster} alt=''></img> Increase your returns with a <b>Boost</b> on your
							earnings
						</div>
						<div className='colors'>
							<div className='bronze'>
								<div></div>
							</div>
							<div className='silver'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={encore} alt=''></img> Automate LeaseToken investments with <b>encore</b>
						</div>
						<div className='colors'>
							<div className='none'>
								<div></div>
							</div>
							<div className='silver'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={money} alt=''></img>
							<b>Leverage</b> your holdings for <b>Loans</b>
						</div>
						<div className='colors'>
							<div className='none'>
								<div></div>
							</div>
							<div className='silver'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={encoreplus} alt=''></img>Automate LeaseTokens & ECON investments with{' '}
							<b>encore+</b>
						</div>
						<div className='colors'>
							<div className='none'>
								<div></div>
							</div>
							<div className='none'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
					<hr />
					<div className='table_1'>
						<div className='text'>
							<img src={clock} alt=''></img> Early access for new tokens with <b>Headstart</b>
						</div>
						<div className='colors'>
							<div className='none'>
								<div></div>
							</div>
							<div className='none'>
								<div></div>
							</div>
							<div className='gold'>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div className='membership_type'>
					<div className='type'>
						<div className='title gold'>GOLD</div>
						<p>
							Investor has <span>$1200</span> in <b>LeaseTokens.</b>
						</p>
						<p>
							To get Gold they need 40% of <b>LeaseToken</b> in <b>ECON</b>.
						</p>
						<p>
							40% of <span>$1200</span> = <span>$480</span> <b>ECON</b>
						</p>
					</div>
					<div className='type'>
						<div className='title silver'>SILVER</div>
						<p>
							Investor has <span>$1200</span> in <b>LeaseTokens.</b>
						</p>
						<p>
							To get Silver they need 25% of <b>LeaseToken</b> in <b>ECON</b>.
						</p>
						<p>
							25% of <span>$1200</span> = <span>$300</span> <b>ECON</b>
						</p>
					</div>
					<div className='type'>
						<div className='title bronze'>BRONZE</div>
						<p>
							Investor has <span>$1200</span> in <b>LeaseTokens.</b>
						</p>
						<p>
							To get Bronze they need 10% of <b>LeaseToken</b> in <b>ECON</b>.
						</p>
						<p>
							10% of <span>$1200</span> = <span>$120</span> <b>ECON</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Membership;
