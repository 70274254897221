/** @format */

import React from 'react';

function Lending() {
	return (
		<div className='lending'>
			<div className='lending_section'>
				<div className='lending_main'>
					<div className='title'>Lending</div>
					<div className='detail'>
						Our unique Lending is <b>interest</b> and <b>debt-free</b>.{' '}
						<span> Available only for Silver & Gold Members.</span>{' '}
					</div>
					<div className='encore'>
						<p>
							<b>encore </b> auto-purchases the cheapest <b>LeaseToken</b> , but the investor must
							self-purchase <b>ECON</b> to maintain the ratio.
						</p>
						<p>
							<b>encore+</b> auto-purchases both <b>LeaseTokens</b> and <b>ECON</b>.
						</p>
					</div>
					<div className='bubbles'>
						<div className='bubble_1'>
							<div className='blue bola'>8-10%</div>
							<div className='desc'>
								Returns on our
								<br /> <b>LeaseTokens</b>
							</div>
						</div>
						<div className='bubble_1'>
							<div className='brown bola'>0%</div>
							<div className='desc'>
								Interest-free <br /> <b>Lending</b>
							</div>
						</div>
						<div className='bubble_1'>
							<div className='gold bola'>16%+</div>
							<div className='desc'>
								Returns on our <br /> <b>Membership</b>
							</div>
						</div>
					</div>
				</div>

				<hr />

				<div className='learnMore'>
					<div className='bigTitle'>Want to learn more about investing?</div>
					<div className='desc'>
						Get information about LeaseTokens, <b> ECON</b> and more by reading the whitepaper
					</div>
					<a href='https://app.econommi.io/static/media/whitepaper.f12c88f7.pdf'>
						<div className='website'>VIEW WHITEPAPER</div>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Lending;
