/** @format */

import React from 'react';
import './style.scss';
import mockup from '../assets/mockup.png';

function Econommi() {
	return (
		<div className='econommiSection'>
			<div className='econommi_desc'>
				<b>econommi</b> is a digital asset platform that allows you to invest in lease contracts and
				earn 10-16% per annum. We currently partner with car dealerships to provide people with
				debt-free car leasing and ownership.
			</div>
			<div className='econommi_mockup'>
				<img src={mockup} alt=''></img>
			</div>
			<hr />
			<div className='tokenizedLeasing'>
				<div className='title'>What is tokenized leasing?</div>
				<div className='tokenizedDetails'>
					<div className='detail_1'>
						<div className='number'>1</div>
						<div className='details'>
							You come to us with a car you would like to lease. It can be a <b>private sale</b> or
							from <b> one of our dealership partners.</b>
						</div>
					</div>
					<div className='detail_2'>
						<div className='number'>2</div>
						<div className='details'>
							We buy the car, and lease it out to you.
							<br />
							The lease is split up into <b>LeaseTokens</b> which are available for purchase on our
							Investment Platform.
							<br /> When you make a payment each month, the total is divided among all{' '}
							<b>LeaseToken</b> owners.
						</div>
					</div>
					<div className='detail_3'>
						<div className='number'>3</div>
						<div className='details'>
							This process allows anyone to start investing with relatively low risk. Their money
							starts working for them, earning an 8-10% return paid out daily.
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div className='leaseTokens'>
				<div className='leaseTokens_title'>LeaseTokens</div>
				<div className='leaseTokens_1'>
					<b>LeaseTokens</b> represent a <span>share of an asset</span> (eg.a car) that we lease
					out. <br />
					They depreciate until the token expires - when lease ends.
				</div>
				<div className='leaseTokens_2'>
					<p>
						<div style={{ color: '#04681A', marginRight: '10px' }}>✓ </div>
						<div>
							<b> LeaseTokens</b> are <span>stable</span> and{' '}
							<span>not directly influenced by external markets</span>
						</div>
					</p>
					<p>
						<div style={{ color: '#04681A', marginRight: '10px' }}>✓ </div>
						<div>
							Money <span>instantly</span> added to your wallet
						</div>
					</p>
					<p>
						<div style={{ color: '#04681A', marginRight: '10px' }}>✓ </div>
						<div>
							Tied to real, physical assets <span>(that you choose)</span>
						</div>
					</p>
					<p>
						<div style={{ color: '#04681A', marginRight: '10px' }}>✓ </div>
						<div>Same returns - 1 year token vs. 5 year token</div>
					</p>
					<p>
						<div style={{ color: '#04681A', marginRight: '10px' }}>✓ </div>
						<div>
							<b>LeaseTokens</b> support the community around you directly!
						</div>
					</p>
				</div>
				<div className='leaseTokens_3'>
					<div className='bold'>How you make money...</div>
					<p>
						<div style={{ fontWeight: '275' }}>1-</div>{' '}
						<div>
							Your Token depreciates (eg. <span>6¢ a day</span>)
						</div>
					</p>
					<p>
						<div style={{ fontWeight: '275' }}>2-</div>{' '}
						<div>
							Your Token pays you out (eg. <span>9¢ a day</span>)
						</div>
					</p>
					<p>
						<div style={{ fontWeight: '275' }}>3- </div>
						<div>
							Amount in between is <b>profit</b> (eg. <span>3¢ a day</span>)
						</div>
					</p>
				</div>
				<div className='leaseTokens_4'>
					<div className='econ'>ECON</div>
					<div className='econ_desc'>
						<div className='title'>ECON</div>
						<div className='desc'>
							<b>ECON (econommiTokens)</b> is our cryptocurrency that offers investors to access the
							Membership + perks.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Econommi;
