/** @format */

import React from 'react';
import './style.scss';
import vanCity from '../assets/vancouver_city.mp4';

function Toppage() {
	return (
		<div className='topPage'>
			<video src={vanCity} type='video/mp4' playsInline autoPlay muted loop></video>
			<div className='topPage_content'>
				<div className='rectangle'></div>
				<div className='title'>
					<div className='small'>the new way to save, invest and spend -</div>
					<div className='large'>
						the <b>econommi</b> app
					</div>
				</div>
			</div>
		</div>
	);
}

export default Toppage;
